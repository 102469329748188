import "./NotFound.css";

const NotFound = () => {
    return (
        <div className="notfound">
            <h1>NotFound</h1>
        </div>
    );
};

export default NotFound;
